import React, { useState } from "react"
import clsx from "clsx"
import HeaderPopup from "@components/header/components/popover/HeaderPopup"
import { useSchools, useRisebinar } from "@hooks/data"
import { container } from "@components/wrapper"
import { PROGRAM_MENU } from "./constant"
import { useCourseCategory } from "@lib/hooks/data/useCourse"
import { LinkHead } from "../HeaderComponent"
import { useDigitalProducts } from "@lib/hooks/data/useDigitalProducts"

export const ServiceDropdown = ({ setIsHover }: any) => {
  const { data: bootcamps } = useSchools("BOOTCAMP")
  const { data: proClasses } = useSchools("PRO_CLASS")
  const { data: risebinars } = useRisebinar()
  const { data: categories } = useCourseCategory()
  const { data: products } = useDigitalProducts()
  const [activeMenu, setActiveMenu] = useState(PROGRAM_MENU[0])

  const PopoverComponent = () => {
    return (
      <div className={clsx(container)}>
        <div className="flex gap-8 text-md font-medium ">
          {PROGRAM_MENU.map((item, index) => (
            <div
              className={clsx(
                item === activeMenu && "text-green border-b-2 pb-2 border-green",
                "text-black",
                "cursor-pointer"
              )}
              onClick={() => setActiveMenu(item)}
              key={index}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className="mt-4 text-2xl font-medium text-black">{activeMenu.title}</div>

        <div className="flex gap-12 mt-4 ">
          <div className="w-2/5 text-black">{activeMenu.description}</div>
          <div className="grid grid-cols-2 gap-2 w-1/2 text-black items-start auto-rows-auto">
            {activeMenu.title === "Bootcamp" &&
              bootcamps?.map((value, index) => (
                <LinkHead key={index} link={`/school/bootcamp/${value.product_slug}`}>
                  {value.product_name}
                </LinkHead>
              ))}
            {/* {activeMenu.title === "Proclass" &&
              proClasses?.map((value, index) => (
                <LinkHead key={index} link={`/school/proclass/${value.product_slug}`}>
                  {value.product_name}
                </LinkHead>
              ))} */}
            {activeMenu.title === "Video Course" &&
              categories?.map((value, index) => (
                <LinkHead link={`/learning/category/${value.category_slug}`} key={index}>
                  {value.category_name}
                </LinkHead>
              ))}
            {activeMenu.title === "Event & Workshop" &&
              risebinars?.map((value, index) => (
                <LinkHead key={index} link={`/risebinar/${value.risebinar_slug}`}>
                  {value.risebinar_title}
                </LinkHead>
              ))}
            {activeMenu.title === "Digital Produk" &&
              products?.map((value, index) => (
                <LinkHead key={index} link={`/digital-product/p/${value.course_slug}`}>
                  {value.course_name}
                </LinkHead>
              ))}
          </div>
        </div>
      </div>
    )
  }

  return <HeaderPopup setIsHover={setIsHover} panel={<PopoverComponent />} buttonLabel="Program" />
}
