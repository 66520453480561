import { CourseCategory } from "@interfaces/course"
import { Product } from "@interfaces/misc"
import CourseServices from "@lib/api/services/course.services"
import MiscServices from "@lib/api/services/misc.services"
import { useLayoutEffect, useState } from "react"

export function useDigitalProducts() {
  const [data, setData] = useState<Product[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const misc = new MiscServices()

  useLayoutEffect(() => {
    const fetcher = async () => {
      try {
        const res = await misc.getDigitalProducts()

        if (res.isSuccess) {
          const result = res.getValue()
          setData(result.data)
        }

        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    }

    fetcher()
  }, [])

  return { data, isLoading }
}
