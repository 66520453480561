import { FlexBox } from "@components/wrapper"
import { Disclosure } from "@headlessui/react"
import clsx from "clsx"
import { FaChevronDown } from "react-icons/fa"

export * from "./AccountAccordion"
export * from "./ProgramAccordion"
export * from "./StaticAccordion"

export const AccordionHead = ({ children, open }: { children: any; open: boolean }) => {
  return (
    <Disclosure.Button
      className={clsx(
        "py-4 font-semibold text-sm w-full px-5",
        "flex justify-between items-center font-urbanist"
      )}
    >
      <span>{children}</span>
      <FaChevronDown className={clsx("rotate-animation", open && "rotate-animation__up")} />
    </Disclosure.Button>
  )
}

export const AccordionPanel: React.FC<any> = ({ children }) => {
  return <Disclosure.Panel className="px-5 grid gap-2">{children}</Disclosure.Panel>
}

interface MobileLinkProps {
  link: string
  children: any
}

export const MobileLink = ({ children, link }: MobileLinkProps) => {
  return (
    <a
      href={link}
      className={clsx(
        "p-3 text-sm font-inter rounded-lg font-normal",
        "active:text-new-blue active:bg-[rgba(0,76,231,0.20)]",
        "hover:text-new-blue hover:bg-[rgba(0,76,231,0.20)]"
      )}
    >
      {children}
    </a>
  )
}
