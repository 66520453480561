export const PROGRAM_MENU = [
  {
    title: "Bootcamp",
    description:
      "Belajar intensif dengan career coaching dan job-guarantee, hanya dalam 3-6 bulan.",
  },
  // {
  //   title: "Proclass",
  //   description:
  //     "Kuasai skill profesional dan raih karier impian dalam waktu singkat, hanya dalam 1-2 bulan.",
  // },
  {
    title: "Video Course",
    description:
      "Belajar bersama tutor profesional dengan kurikulum berbasis industri di mana pun, kapan pun.",
  },
  {
    title: "Event & Workshop",
    description: "Upgrade skill dan kejar karier impian dengan event & workshop berkualitas.",
  },
  {
    title: "Digital Produk",
    description: "Upskilling jadi mudah dan fleksibel dengan e-book, video course, dan template yang bisa diakses selamanya.",
  },
]
